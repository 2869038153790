<template>
  <div class="vg_wrapper">
    <el-card>
      <el-row>
        <el-col> </el-col>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
          ><i class="el-icon-refresh-right"></i> 刷新</el-button
        >
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" border v-loading="loading" @row-dblclick="dbClickJp" class="mtrTab">
            <el-table-column label="物料编号" prop="mtrl_no" />
            <el-table-column label="物料图片" align="center">
              <template slot-scope="scope">
                <el-image v-if="scope.row.imge_url" class="vd_elimg" :src="formatPic(scope, 's')" fit="fill"> </el-image>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="物料名称" prop="mtrl_name" />
            <el-table-column label="供应商" prop="supp_name" />
            <el-table-column label="物料规格" prop="mtrl_spec" show-overflow-tooltip>
              <template slot-scope="scope">
                <div v-if="scope.row.mtrl_spec">
                  {{ scope.row.mtrl_spec }}
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="物料类型">
              <template slot-scope="scope">
                <div v-if="scope.row.mtrl_type >= 0">
                  {{ scope.row.mtrl_type | formatLeavType }}
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="物料成分" prop="mtrl_comp">
              <template slot-scope="scope">
                <div v-if="scope.row.mtrl_comp">
                  {{ scope.row.mtrl_comp }}
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="物料单位" prop="mtrl_unit">
              <template slot-scope="scope">
                <div v-if="scope.row.mtrl_unit">
                  {{ scope.row.mtrl_unit }}
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="潘通色号" prop="mtrl_color">
              <template slot-scope="scope">
                <div v-if="scope.row.mtrl_color">
                  {{ scope.row.mtrl_color }}
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="物料门幅" prop="mtrl_width">
              <template slot-scope="scope">
                <div v-if="scope.row.mtrl_width">
                  {{ scope.row.mtrl_width | formatMtrlWidth }}
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="物料毛高" prop="mtrl_thick">
              <template slot-scope="scope">
                <div v-if="scope.row.mtrl_thick">
                  {{ scope.row.mtrl_thick | formatMtrlThick }}
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="物料单价" prop="mtrl_price">
              <template slot-scope="scope">
                <div v-if="scope.row.mtrl_prod_price || scope.row.mtrl_prod_price === 0">
                  {{ scope.row.mtrl_prod_price | formatMtrlPrice }}
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="80px">
              <template slot-scope="scope">
                <el-button type="text" size="mini" @click="toCompleted(scope.$index, scope.row)" v-show="scope.row.scan">完成</el-button>
                <span v-show="!scope.row.scan" class="vd_completed">已完成</span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mtrlAPI } from '@api/modules/mtrl';
import helper from '@assets/js/helper.js';
export default {
  name: 'MtrlPush9001',
  data() {
    return {
      tableData: [],
      loading: true,
      isEditObj: []
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    for (let i in this.tableData) {
      let scan = 'edit' + i;
      this.isEditObj.push({
        scan: true
      });
    }
  },
  filters: {
    // 物料类型
    formatLeavType(row) {
      if (row === 0) {
        return '原面料';
      } else if (row === 1) {
        return '辅料';
      } else if (row === 2) {
        return '包材';
      }
    },
    // 单价
    formatMtrlPrice(row) {
      return helper.haveFour(row);
    },
    formatMtrlWidth(row) {
      return helper.haveFour(row) + '米';
    },
    formatMtrlThick(row) {
      return helper.reservedDigits(row) + '厘米';
    }
  },
  methods: {
    initData() {
      this.getPushList();
    },
    //获取信息
    getPushList() {
      get(mtrlAPI.getPushMtrls9002)
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //操作
    toCompleted(index, row) {
      post(mtrlAPI.updateScanById, { mtrl_id: row.mtrl_id })
        .then(res => {
          if (res.data.code === 0) {
            this.$set(row, 'scan', false);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 图片类型
    formatPic(scope, type) {
      return this.helper.picUrl(scope.row.imge_url, type, scope.row.create_time);
    },
    // 双击
    dbClickJp(row) {
      this.jump('/mtrl_edit', { perm_id: this.$route.query.perm_id, form_id: row.mtrl_id, mtrl_id: row.mtrl_id });
    },
    buttonRefresh() {
      this.getPushList;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_completed {
  color: $color-secondary-text;
}
.vd_elimg {
  margin-top: 5px;
  width: 60px;
  height: 60px;
}
.mtrTab {
  margin-top: 10px;
  cursor: pointer;
}
</style>
